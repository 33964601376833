"use client";
import { BsFillMoonStarsFill, BsSunFill } from "react-icons/bs";


import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import Projects from './components/pages/Projects';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';
import Skills from './components/pages/Skills';
import Now from './components/pages/Now';

import { useState, useEffect } from 'react';

import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') || 'dark');
  const toggleMode = () => {
    setDarkMode(darkMode === 'dark' ? 'light' : 'dark');
  };
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen((prev) => !prev);
    // document.body.classList.toggle('overflow-hidden');
    setThamActive(!thamActive);
  };

  const [thamActive, setThamActive] = useState(false);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);
  return (
    <BrowserRouter>
    <div className={(darkMode === 'dark' ? "dark" : "")}>
      <main id="mainBackground" className={"min-h-screen bg-white px-10 md:px-20 lg:px-40 dark:bg-zinc-900 cursor-pointerBlack dark:cursor-pointerWhite transition-colors duration-1000"}>
        <header>
          <nav className="pt-20 flex justify-between z-10">
            <a className="flex items-center gap-3" href="/">
              <img alt="logo" className="brightness-0 dark:brightness-100 transition duration-1000" height={40} width={40} src="/images/logo.png"/>
              {/* <span class="self-center text-2xl font-Montserrat tracking-wider font-semibold whitespace-nowrap dark:text-white">KEVIN DO</span> */}
            </a>
            
            <section className="flex lg:hidden align-middle items-center">
              <div className={(thamActive ? 'tham-active ' : '') + " h-max tham tham-e-squeeze tham-w-6"}>
                <button aria-label="hamburger menu" className="tham-box" onClick={toggleNav}>
                  <div className="tham-inner bg-zinc-900 dark:bg-white"  />
                </button>
              </div>

            <div className={(isNavOpen ? "showMenuNav" : "hideMenuNav") + (darkMode === 'dark' ? " dark" : "")}>
              <ul className="flex flex-col items-center justify-between min-h-[250px] py-2">
                <li className="navLink" onClick={toggleNav}>
                  <NavLink to="/" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">About</NavLink>
                </li>
                <li className="navLink" onClick={toggleNav}>
                  <NavLink to="/skills" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Skills + Experience</NavLink>
                </li>
                <li className="navLink" onClick={toggleNav}>
                  <NavLink to="/projects" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Projects</NavLink>
                </li>
                <li className="navLink" onClick={toggleNav}>
                  <a href="Kevin_Do_resume.pdf" download="Kevin_Do_resume.pdf" className="-ml-1 bg-pink-500 bg-opacity-60 p-1 self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Resume</a>
                </li>
                <li className="">
                  <BsFillMoonStarsFill onClick={toggleMode} className="cursor-linkBlack text-black text-2xl dark:hidden dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
                  <BsSunFill onClick={toggleMode} className="cursor-linkBlack hidden text-2xl text-black dark:inline dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
                </li>
              </ul>
            </div>
          </section>
            
            <ul className="items-center gap-5 hidden lg:flex">
              <li className="navLink">
                <NavLink to="/" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">About</NavLink>
              </li>
              <li className="navLink">
                <NavLink to="/skills" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Skills + Experience</NavLink>
              </li>
              <li className="navLink">
                <NavLink to="/projects" className="self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Projects</NavLink>
              </li>
              <li className="navLink">
                <a href="Kevin_Do_resume.pdf" download="Kevin_Do_resume.pdf" className="-ml-1 bg-pink-500 bg-opacity-60 p-1 self-center whitespace-nowrap text-black font-Montserrat dark:text-white transition-colors duration-1000">Resume</a>
              </li>
              <li className="hidden min-[465px]:block">
                <BsFillMoonStarsFill onClick={toggleMode} className="cursor-linkBlack text-black text-2xl dark:hidden dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
                <BsSunFill onClick={toggleMode} className="cursor-linkBlack hidden text-2xl text-black dark:inline dark:text-white dark:cursor-linkWhite transition-colors duration-1000" />
              </li>
            </ul>
          </nav>
        </header>
        <Routes className="opacity-100 transition-opacity duration-300">
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path='*' element={<NotFound />}/>
          <Route path='/now' element={<Now />}/>
          <Route path="/" element={<About />} />
        </Routes>
        
      </main>
    </div>
    </BrowserRouter>
  );
}

export default App;
