import React from 'react'


function NotFound() {
  return (
    <div>
        <div className='pt-20 mx-auto text-center max-w-md'>
          <h1 className='font-Montserrat text-3xl font-bold text-zinc-800 dark:text-zinc-200'>Page not found!</h1>
          <h1 className='font-Montserrat text-9xl font-black text-pink-500'>404</h1>
          <h1 className='font-Montserrat text-3xl font-bold text-zinc-800 dark:text-zinc-200'>You seem to be lost.</h1>
            
        </div>
    </div>
  )
}

export default NotFound